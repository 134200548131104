import * as React from "react"
import styled from "styled-components"

const Text = props => {
  return (
    <Container {...props}>
      {props.children}
    </Container>
  )
}

const Container = styled.p`
  font-size: 16px;
  line-height: 2;
  color: #333333;
  white-space: pre-wrap;
`;

export default Text
