import * as React from "react"
import styled from "styled-components"

import PartyImage from "@components/common/PartyImage"

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Card = props => {
  const {
    title,
    area,
    startAt,
    createdAt,
    src,
    titleComponent
  } = props;
  return (
    <Container {...props}>
      <ImageWrapper>
        {src.includes('http')?
          <Image
            src={src}
            alt={area? 'Cuddle Place（カドル プレイス）の既婚者合コン・サークル':'Cuddle Place（カドル プレイス）のコラム'}
          />:
          <PartyImage
            src={src}
            alt={area? 'Cuddle Place（カドル プレイス）の既婚者合コン・サークル':'Cuddle Place（カドル プレイス）のコラム'}
            ImageStyle={ImageStyle}
          />
        }
      </ImageWrapper>
      <Content>
        {titleComponent === 'h3' && <Head3>{title}</Head3>}
        {titleComponent === 'h4' && <Head4>{title}</Head4>}
        <Info>
          {area &&
            <Location>
              <LocationOnOutlinedIcon sx={IconStyle} />
              <Text style={{letterSpacing: '0.1em'}}>{area}</Text>
            </Location>
          }
          {startAt &&
            <StartAt>
              <CalendarMonthRoundedIcon sx={IconStyle} />
              <Text>{startAt}</Text>
            </StartAt>
          }
          {createdAt &&
            <CreatedAt>
              <Text>{createdAt}</Text>
            </CreatedAt>
          }
        </Info>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 30%;
  padding-top: 30%;
  height: 0px;
`;

const ImageStyle = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: '50% 50%'
}

const Image = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;

const Content = styled.div`
  width: 66%;
`;

const Head3 = styled.h3`
  font-size: 14px;
  line-height: 1.7;
  color: #333333;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const Head4 = styled.h4`
  font-size: 14px;
  line-height: 1.7;
  color: #333333;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const Info = styled.div`
  display: flex;
  margin-top: 4px;
`;

const Location = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyle = {
  fontSize: '16px',
  color: '#999999',
  marginRight: '6px'
}

const StartAt = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const CreatedAt = styled.div`
`;

const Text = styled.span`
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0em;
  color: #999999;
`;

export default Card
