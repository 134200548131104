import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Service = () => {
  return (
    <Container>
      <Body>
        <h3>
          <StaticImage
            src="../../images/top_cuddleplace.png"
            alt="既婚者向け総合メディア Cuddle Place (カドル プレイス)とは"
            width={225}
          />
        </h3>
        <Text>
          結婚後も異性の友達・セカンドパートナーを簡単に探せる、既婚者専用マッチングアプリ「Cuddle（カドル）」や、既婚者合コン・サークル・パーティー情報の紹介、編集部による体験談・コラムの掲載など、既婚者向けの情報をご案内しています。
        </Text>
      </Body>
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 24px;
`;

const Body = styled.div`
  border: 1px solid #333333;
  padding: 40px 30px;
`;

const Text = styled.p`
  font-family: 'Noto Sans CJK JP';
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.05em;
  color: #333333;
  margin-top: 8px;
`;

export default Service
