import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { format } from 'date-fns'
import ja from "date-fns/locale/ja"

import Head from "@components/common/Head2"
import Text from "@components/common/Text"
import Button from "@components/common/Button"
import Card from "@components/common/Card"

const Party = props => {
  const {
    parties
  } = props;
  const items = parties.map(elem => ({
    id: elem.id,
    title: elem.title,
    area: elem.area,
    startAt: `${format(new Date(elem.date), 'M/d(E)', { locale: ja })} ${elem.startAt.split(':')[0]}:${elem.startAt.split(':')[1]}〜`,
    src: `${elem.id.split('_')[0]}`,
  })).slice(0, 5);
  return (
    <Container>
      <Head
        main={`既婚者合コン`}
        sub="Matchmaking Party"
      />
      <Text
        style={{
          marginTop: '24px',
          marginBottom: '48px'
        }}
      >
        既婚者同士で集まって、楽しく飲み会やパーティー。自分に合った合コン・サークルで素敵な出会いを探しましょう。
      </Text>
      {items.map(elem => {
        return (
          <Card
            {...elem}
            titleComponent='h3'
            onClick={() => navigate(`/party/${elem.id}`)}
            key={elem.id}
          />
        )
      })}
      <Action>
        <Button
          variant='outlined'
          size='middle'
          font='small'
          width='75%'
          onClick={() => navigate('/parties/')}
        >
          もっと見る
        </Button>
      </Action>
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 24px;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export default Party
