import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Head from "@components/common/Head2"
import Text from "@components/common/Text"
import Button from "@components/common/Button"

const MatchingApp = () => {
  return (
    <Container>
      <Head
        main={`既婚者専用\nマッチングアプリ`}
        sub="Dating App"
      />
      <ScreenshotWrapper>
        <StaticImage
          src="../../images/top_matchingapp.png"
          alt="既婚者専用マッチングアプリ「Cuddle（カドル）」"
          style={ScreenshotStyle}
        />
      </ScreenshotWrapper>
      <Text>
        マッチングアプリ「Cuddle（カドル）」は、結婚後も異性の友達・セカンドパートナーを簡単に探せる既婚者専用サービスです。
      </Text>
      <Action>
        <Button
          variant='outlined'
          size='middle'
          font='small'
          width='75%'
          onClick={() => navigate('https://www.cuddle-jp.com/?utm_source=cuddle-place&utm_medium=referral&utm_campaign=cuddle-place_normal')}
        >
          もっと見る
        </Button>
      </Action>
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 24px;
`;

const ScreenshotWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 52px;
  margin-bottom: 32px;
`;

const ScreenshotStyle = {
  'width': '75%'
}

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export default MatchingApp
