import * as React from "react"
import Amplify, { API, graphqlOperation }  from "aws-amplify"
import { addDays, format } from 'date-fns'

import awsmobile from "../aws-exports"
import { partyByAreaByDate } from "@graphql/queries"

Amplify.configure(awsmobile);

const useIndex = () => {
  const [parties, setParties] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      try {
        const startDate = format(addDays(new Date(), 7), 'yyyy-MM-dd');
        const endDate = format(addDays(new Date(), 14), 'yyyy-MM-dd');
        const res = await API.graphql(graphqlOperation(partyByAreaByDate, {
          area: '東京',
          date: { between: [startDate, endDate] },
          filter: {
            maleVacancy: { eq: true },
            femaleVacancy: { eq: true },
          },
          limit: 1000,
        }));
        const items = res.data.partyByAreaByDate.items;
        setParties(items);
      } catch(err) {
        console.log(err);
      }
    })();
  }, []);
  return { parties };
}

export default useIndex