import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import HeaderMenu from '@components/common/HeaderMenu'

const Top = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  return (
    <Container>
      <StaticImage
        src="../../images/top_hero.png"
        alt="既婚者向け総合メディア Cuddle Place (カドル プレイス)とは"
      />
      <div onClick={() => setOpenDrawer(true)}>
        <StaticImage
          src="../../images/icons/menu_white.svg"
          alt="メニュー"
          style={MenuStyle}
        />
      </div>
      <Section>
        <Message>
          既婚者に<br />
          ほっと出来る<br />
          出会いの場を
        </Message>
        <Logo>
          <StaticImage
            src="../../images/logo_for_index-top.png"
            alt="既婚者向け総合メディア - Cuddle Place | 既婚者合コン・マッチングアプリ"
            width={178}
          />
        </Logo>
      </Section>
      <HeaderMenu openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`;

const MenuStyle = {
  cursor: 'pointer',
  position: 'absolute',
  top: '36px',
  right: '24px',
}

const Section = styled.div`
  position: absolute;
  bottom: 8%;
  left: 24px;
`;

const Message = styled.span`
  font-weight: 600;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
`;

const Logo = styled.h1`
  margin-top: 25px;
`;

export default Top
