import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import format from 'date-fns/format'

import Head from "@components/common/Head2"
import Text from "@components/common/Text"
import Button from "@components/common/Button"
import Card from "@components/common/Card"

const Column = props => {
  return (
    <Container>
      <Head
        main={`既婚者コラム`}
        sub="Column"
      />
      <Text
        style={{
          marginTop: '24px',
          marginBottom: '48px'
        }}
      >
        既婚者合コン・サークルへの潜入レポートなど、ここでしか読めないCuddle Place（カドル プレイス）編集部のコラムをご覧いただけます。
      </Text>
      {props.data.map((elem, index) => {
        const node = elem.node;
        if(index > 3) return null
        return (
          <Card
            src={node.hero_image.url}
            title={node.title}
            createdAt={format(new Date(node.publishedAt), 'yyyy.MM.dd')}
            onClick={() => navigate(`/blog/${node.blogId}/`)}
            titleComponent='h3'
            key={`card${index + 1}`}
          />
        )
      })}
      <Action>
        <Button
          variant='outlined'
          size='middle'
          font='small'
          width='75%'
          onClick={() => navigate('/blogs/')}
        >
          もっと見る
        </Button>
      </Action>
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 24px;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export default Column
